import React from 'react';
import { DataGrid, GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import { deDE } from '@mui/x-data-grid/locales';
import Chip from 'components/common/Chip';
import Container from 'components/common/Container';

import Stack from 'components/common/Stack';
import ViewIcon from '@mui/icons-material/ArticleOutlined';
import useAdminCompanyJobs from 'hooks/useAdminCompanyJobs';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getLanguagePreference } from 'utils/browser';
import { getDurationBetweenDates, getLengthOfTime } from 'utils/date';
import Box from 'components/common/Box';
import colors from 'constants/colors';
import Card from 'components/common/Card';
import Button from 'components/common/Button';
import useUserCompanyCollectionOverview from 'hooks/useUserCompanyCollectionOverview';
import { getClimateScoreAsPercent } from 'utils/tools';
import Typography from 'components/common/Typography';

function CustomNoRowsOverlay() {
  const { t } = useTranslation();

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent={'center'}
      height={'100%'}
      spacing={2}
    >
      <Box>{t('This collection is empty.')}</Box>
      <Button
        variant="contained"
        color="primary"
        onClick={() => alert('Button clicked!')}
      >
        {t('Take Action')}
      </Button>
    </Stack>
  );
}

interface ScoreProps {
  score?: number; // score is optional and ranges from 0 to 100
}

const ScoreComponent: React.FC<ScoreProps> = ({ score = 0 }) => {
  // Normalize score to fit within a scale of 0-10
  const normalizedScore = Math.round((score / 100) * 10);

  return (
    <Box display="flex" alignItems="center" height={'100%'}>
      {/* Display the numerical score */}
      <Typography variant="body1" sx={{ marginRight: 1, width: '24px' }}>
        {score}
      </Typography>

      {/* Display the score bar */}
      <Box display="flex">
        {[...Array(10)].map((_, index) => (
          <Box
            key={index}
            width={6}
            height={16}
            borderRadius={1}
            marginRight={'2px'}
            bgcolor={
              index < normalizedScore ? colors.secondary300 : colors.neutral200
            }
          />
        ))}
      </Box>
    </Box>
  );
};

function QualatativeDescripterChip({
  type,
}: {
  type?: 'VERY_GOOD' | 'GOOD' | 'FAIR' | 'POOR';
}) {
  const { t } = useTranslation();
  return type ? (
    <Chip
      label={
        type === 'GOOD' ? t('High') : type === 'FAIR' ? t('Medium') : t('Low')
      }
      style={{
        borderRadius: 4,
        fontSize: '12px',
        color: colors.neutral600,
        height: '24px',
        backgroundColor:
          type === 'GOOD'
            ? colors.primary100
            : type === 'FAIR'
            ? colors.warning100
            : colors.warning100,
      }}
    />
  ) : null;
}

function UserCompanyCollectionList() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { companyId } = useParams();
  const [page, setPage] = React.useState(0);
  const { data, isLoading } = useUserCompanyCollectionOverview({
    page: page + 1,
    collection_id: '00e23389-0f98-46fe-b118-44cf4f9cac62',
  });

  const rowCount = data ? data.length : 0;
  const rows =
    data?.map((d: any) => ({
      ...d,
      id: Math.random(),
    })) || [];
  // const rowCount = data?.count || 0;
  // const rows = data && data.results ? data.results : [];

  const columns: GridColDef[] = [
    {
      field: 'company_name',
      headerName: t('Name'),
      flex: 0,
      minWidth: 170,
      sortable: false,
    },
    {
      field: 'reporting_company_name',
      headerName: t('Reporting company'),
      flex: 0,
      minWidth: 170,
      sortable: false,
    },
    {
      field: 'reporting_company_climate_score_overall',
      headerName: t('Climate score'),
      flex: 0,
      minWidth: 170,
      sortable: false,
      renderCell: (params) => (
        <ScoreComponent
          score={getClimateScoreAsPercent(
            params.row?.reporting_company_climate_score_overall ||
              params.row?.company_climate_score_overall
          )}
        />
      ),
    },
    {
      field: 'company_maturity_level_crc',
      headerName: t('Maturity level'),
      flex: 0,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => (
        <QualatativeDescripterChip
          type={params?.row.company_maturity_level_crc}
        />
      ),
    },
    {
      field: 'company_public_data_screening_status',
      headerName: t('Public data'),
      flex: 0,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => (
        <Stack
          spacing={1}
          direction={'row'}
          alignItems={'center'}
          height={'100%'}
        >
          <Typography variant={'body1'}>
            {params?.row?.company_public_data_screening_status === 'COMPLETE'
              ? t('Complete')
              : params?.row?.company_public_data_screening_status ===
                'IN PROGRESS'
              ? t('In progress')
              : ''}
          </Typography>
        </Stack>
      ),
    },
    {
      field: 'company_public_data_screening_last_date',
      headerName: t('Last updated'),
      flex: 0,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => {
        const longAgo = getDurationBetweenDates(
          params.row?.reporting_company_public_data_screening_last_date ||
            params.row?.company_public_data_screening_last_date,
          new Date().toUTCString()
        );
        return (
          <Box
            whiteSpace={'nowrap'}
            overflow={'hidden'}
            textOverflow={'ellipsis'}
            title={longAgo}
          >
            {longAgo}
          </Box>
        );
      },
    },
    {
      field: 'company_private_data_status',
      headerName: t('Private data'),
      flex: 0,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => (
        <Stack
          spacing={1}
          direction={'row'}
          alignItems={'center'}
          height={'100%'}
        >
          <Typography variant={'body1'}>
            {params?.row?.company_private_data_status ===
            'ACCESS_CAN_BE_REQUESTED'
              ? t('Request')
              : params?.row?.company_private_data_status === 'ACCESS_REQUESTED'
              ? t('Requested')
              : params?.row?.company_private_data_status === 'ACCESS_GRANTED'
              ? t('Granted')
              : ''}
          </Typography>
        </Stack>
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: t('Summary'),
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<ViewIcon />}
          label={t('View')}
          onClick={() => navigate(`/admin/job/${params.id}`)}
        />,
      ],
    },
  ];
  const localeText = {
    ...(getLanguagePreference({ twoLetter: true }) === 'de'
      ? deDE.components.MuiDataGrid.defaultProps.localeText
      : {}),
    noRowsLabel: t('This collection is empty.'),
  };

  return (
    <Box>
      <DataGrid
        loading={isLoading}
        localeText={localeText}
        rows={rows}
        columns={columns}
        rowCount={rowCount}
        pageSizeOptions={[100]}
        initialState={{
          sorting: {
            sortModel: [{ field: 'name', sort: 'desc' }],
          },
          pagination: { paginationModel: { pageSize: 100 } },
        }}
        pagination
        paginationMode="server"
        onPaginationModelChange={(params: any) => setPage(params.page)}
        sx={{ minHeight: 300 }}
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
        }}
      />
    </Box>
  );
}

export default UserCompanyCollectionList;
