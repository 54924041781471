import { useTranslation } from 'react-i18next';
import Button from 'components/common/Button';
import useAdminCompanyCollection, {
  useAdminCompanyCollectionRecordComplete,
} from 'hooks/useCompanyCollection';

function Component({
  companyId,
  recordId,
}: {
  recordId: string;
  companyId: string | undefined;
}) {
  const { t } = useTranslation('common');

  const { refetch: refetchCompanyCollections } = useAdminCompanyCollection({
    enabled: false,
    companyId: companyId,
  });

  const { mutate, isLoading } =
    useAdminCompanyCollectionRecordComplete(recordId);

  return (
    <Button
      disabled={isLoading}
      variant="contained"
      color="info"
      onClick={() => {
        mutate({
          onSuccess: () => {
            refetchCompanyCollections();
          },
        });
      }}
      sx={{
        marginBottom: 1,
      }}
    >
      {t('Complete')}
    </Button>
  );
}

export default Component;
