import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import Grid from '@mui/material/Grid';
import useAuth from 'hooks/useAuth';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import useUserCompany from 'hooks/useUserCompany';

// import Box from 'components/common/Box';
import Typography from 'components/common/Typography';
import Alert from '@mui/material/Alert';

import Stack from 'components/common/Stack';
import api from 'constants/api';
import AppLoading from 'components/common/AppLoading';
import { getClimateScoreCategoryScores } from 'utils/tools';

import Box from 'components/common/Box';
import ProgramOverviewList from './CollectionList';
import SelectMenu from 'components/common/SelectMenu';

function UserDashboardScorecard() {
  const auth = useAuth();

  const { t } = useTranslation('common');
  const { userCompany, userCompanyCPALink } = useUserCompany();

  const { isLoading, data, isError } = useQuery({
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    queryKey: ['scorecard', userCompany.id],
    retry: false,
    queryFn: async () => {
      const res = await axios.get(
        `${api.url}/companies/scorecard/${userCompany.id}`,
        auth.requestConfig
      );
      return res.data as {
        scorecard: { overall: number; categories: { score: number }[] };
      };
    },
  });

  const {
    emissionsCalculation,
    strategyAndControl,
    targets,
    decarbonizationMeasures,
    emissionsPerformance,
  } = getClimateScoreCategoryScores(data?.scorecard);

  const showCPALinkBanner = userCompanyCPALink.url;

  return (
    <Grid container spacing={{ xs: 2, sm: 3 }}>
      <Grid item xs={12}>
        {isLoading ? (
          <AppLoading />
        ) : isError ? (
          <Alert severity="error">{t('Something went wrong')}</Alert>
        ) : (
          <Grid container spacing={{ xs: 2, sm: 3 }}>
            <Grid item xs={12}>
              <Typography variant={'h4'} sx={{ pb: 6 }}>
                {t('Collections')}
              </Typography>
              <Box maxWidth={'400px'} pb={2}>
                <SelectMenu
                  items={[t('Scope 3 Group Oct/Nov 2024')]}
                  handleChange={(e: any) => {
                    console.log('click');
                  }}
                  value={t('Scope 3 Group Oct/Nov 2024')}
                />
              </Box>
              <ProgramOverviewList />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default observer(UserDashboardScorecard);
